import React, { useEffect, HtmlHTMLAttributes, useState } from "react";
import { connect } from "react-redux";
import { history } from "../../../../../routers/AppRouter";
import { getAlertItems } from "../../../../../actions/alert/alert-items";
import styled from 'styled-components';
import MainButton from "../../../../../components/MainButton";
import moment from "moment";
import colors from '../../../../../styles/colors';
import { getEmailStat } from "../../../../../actions/portal/stats-email";
import { EmailContentStatTypes, EmailLeadStat, EmailStatsTypes } from "../../stats-types";
import { Link, useParams } from "react-router-dom";
import { getEmailContentStat, getEmailContentStats } from "../../../../../actions/portal/email-content-stat";
import FileSelector from "../../../../../components/FileSelector";
import { BASE_URL } from "../../../../../utility/constants";
import { createEmailLeadStat, getEmailLeadStat, getEmailLeadStats } from "../../../../../actions/portal/email-lead-stat";
import ABSmallButton from "../../../../../components/ABSmallButton";
import ABFormButton from "../../../../../components/ABFormButton";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { SUCCESS } from "dropzone";

const PageWrapper = styled.div`
  max-width: 1100px;
  margin: 0 auto;
  padding: 20px;
`;

const HeadingContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const TableStyle = styled.div`
  padding: 40px 0;
`;

const TableHeading = styled.div`
  display: flex;
`;

const TableRow = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  :hover {
    background: ${colors.gray100};
  }
`;

const TableCellHeading = styled.div`
  padding: 10px;
  width: 125px;
  font-weight: bold;
`;

const TableCell = styled.div`
  padding: 10px;
  width: 125px;
  word-wrap: break-word; /* Ensures long words break and wrap */
  overflow-wrap: anywhere; /* Wraps text even for long URLs or words */
`;

const TableCellHeadingLarge = styled.div`
  padding: 10px;
  width: 300px;
  font-weight: bold;
  word-wrap: break-word;
  overflow-wrap: anywhere;
`;

const TableCellLarge = styled.div`
  padding: 10px;
  width: 300px;
`;

const FilterContainer = styled.div`
  display: flex;
  margin: 20px 0 0 0;
`;

interface FilterButtonProps {
  activeFilterTitle?: boolean;
}

const FilterButton = styled.button <FilterButtonProps>`
  background: ${props => props.activeFilterTitle ? colors.blue : colors.white};
  color: ${props => props.activeFilterTitle ? colors.white : colors.black};
  padding: 10px 15px;
  cursor: pointer;
  border: none;
  border-right: 1px solid ${colors.lightGray};
  border-top: 1px solid ${colors.lightGray};
  border-bottom: 1px solid ${colors.lightGray};
  border-left: 1px solid ${colors.lightGray};

  &:nth-child(2), &:last-child {
    border-left: none;
  }
  :hover {
      background-color: ${props => props.activeFilterTitle ? '' : colors.gray100};
  }
`;

const StyledHeading = styled.div`
  padding: 10px 10px 10px 0;
  font-size: 40px;
`;

const SubHeading = styled.div`
  padding: 0;
  font-size: 19px;
  font-weight: bold;
  padding-bottom: 10px;
`;

const Section = styled.div`
  padding: 5px 0;
`;

const MainImage = styled.div`
  img {
    width: 300px;
    height: 300px;
  }
`;

const ButtonPadding = styled.div`
  padding: 10px 0;
`;

interface RouteParams {
  id: string;
}

const EmailContentDetails = (props: any) => {

  const [activeFilter, setActiveFilter] = useState('draft');
  const { id } = useParams<RouteParams>();
  const [selectedFile, setSelectedFile] = useState<File | null>(null);

  useEffect(() => {
    // console.log('id: ', id);

    if (id) {
      props.getEmailContentStat(id);
      props.getEmailLeadStats(id);
    }
  }, [id]);

  useEffect(() => {
    if (props.uploadLeadSuccess) {
      toast.success("Leads uploaded successfully!");
    }
  }, [props.uploadLeadSuccess])

  const handleButtonClick = (e: React.MouseEvent<HTMLButtonElement>, title: string) => {
    setActiveFilter(title);
    // if (title === 'all') {
    //     props.getStatsEmail();
    // } else {
    //     props.getStatsEmail({ status: title });
    // }
  }

  const createDetailForm = () => {
    // history.push({
    //     pathname: `/portal/email-content-stats-form/`,
    //     search: `?emailStatId=${id}&alertItemId=${props.emailStat.alertItem.id}&alertIssueId=${props.emailStat.alertItem.issue}`,
    // });
  }

  const handeViewAlert = (id: number) => {
    history.push(`/alert/${id}`);
  }

  const handeDetailNavigate = () => {

  }

  const handleFileSelect = (file: File | null) => {
    setSelectedFile(file);
  };

  const handleCSVUpload = () => {
    if (!selectedFile) {
      console.log('No file selected');
      return;
    }
    const formData = new FormData();
    formData.append('csv', selectedFile);
    formData.append('clientId', props.emailContentStat.clientId);

    props.createEmailLeadStat(formData, id);
  }

  const handleEdit = () => {
    // console.log(props.emailContentStat.id);
    history.push({
      pathname: `../../email-content-stats-form/${props.emailContentStat.id}/`,
      search: `?emailStatId=${props.emailStat.id}&alertItemId=${props.emailStat.alertItem.id}&alertIssueId=${props.emailStat.alertItem.issue}`
    });
  };

  return (
    <>
      <PageWrapper>
        <div><Link to="../../email-performance">Performance</Link> - <Link to={`../${props.emailStat.id}`}>Issue Details</Link> - Content Details</div>

        <HeadingContainer>
          <StyledHeading>Email Content Details</StyledHeading>
        </HeadingContainer>

        <div>
          {
            (props.emailContentStat && props.emailContentStat) &&
            <div>
              <Section><MainImage><img src={props.emailContentStat.imageUrl} /></MainImage></Section>
              <Section><strong>Content Id:</strong> {props.emailContentStat.id}</Section>
              <Section><strong>Client Id:</strong> {props.emailContentStat.clientId}</Section>
              <Section><strong>Date:</strong> {moment(props.emailContentStat.date).format('MMM. Do, YYYY')}</Section>
              <Section><strong>Content Type:</strong> {props.emailContentStat.contentType}</Section>
              <Section><strong>Category:</strong> {props.emailContentStat.category}</Section>
              <Section><strong>External Link:</strong><br /><a href={props.emailContentStat.externalLink}>{props.emailContentStat.externalLink}</a></Section>
              <Section><button onClick={handleEdit}>Edit Content Stats</button></Section>
            </div>
          }
          {
            id &&
            <div>
              <h3>Upload Leads CSV</h3>
              <FileSelector onFileSelect={handleFileSelect} />
              {selectedFile && <p><strong>Selected File:</strong> {selectedFile.name}</p>}
              {
                selectedFile &&
                <ButtonPadding>
                  <MainButton onClick={handleCSVUpload} title="Upload" disabled={!selectedFile} />
                </ButtonPadding>
              }
              {
                props.uploadLeadSuccess &&
                <div>Upload Succes</div>
              }
            </div>
          }
        </div>

        <TableStyle>
          <SubHeading>Leads for this content</SubHeading>

          <TableHeading>
            <TableCellHeading>Email</TableCellHeading>
            <TableCellHeading>First Name</TableCellHeading>
            <TableCellHeading>Last Name</TableCellHeading>
            <TableCellHeading>Company</TableCellHeading>
            <TableCellHeading>Job Title</TableCellHeading>
            <TableCellHeading>Clicks</TableCellHeading>
            <TableCellHeading>Priority</TableCellHeading>
            <TableCellHeading>Link</TableCellHeading>
          </TableHeading>
          {
            (props.leads && props.leads.length > 0) &&
            props.leads.map((item: EmailLeadStat) => {
              return (
                <TableRow key={item.email} onClick={() => handeDetailNavigate()}>
                  <TableCell>{item.email}</TableCell>
                  <TableCell>{item.firstName || 'N/A'}</TableCell>
                  <TableCell>{item.lastName || 'N/A'}</TableCell>
                  <TableCell>{item.company || 'N/A'}</TableCell>
                  <TableCell>{item.jobTitle || 'N/A'}</TableCell>
                  <TableCell>{item.clicks ?? 0}</TableCell>
                  <TableCell>{item.priority ? '1' : '0'}</TableCell>
                  <TableCell>
                    {item.link ? (
                      <a href={item.link} target="_blank" rel="noopener noreferrer">
                        Link
                      </a>
                    ) : (
                      'NA'
                    )}
                  </TableCell>
                </TableRow>
              );
            })
          }
        </TableStyle>
        <ToastContainer />
      </PageWrapper>
    </>
  )
}

const mapStateToProps = (state: any) => {
  return {
    emailStat: state.statsEmail.emailStat,
    emailContentStat: state.emailContentStat.singleItem,
    leads: state.emailLeadStat.data,
    success: state.emailLeadStat.success,
    uploadLeadSuccess: state.emailLeadStat.uploadLeadSuccess,
  };
};

const mapDispatchToProps = (dispatch: any) => ({
  getEmailContentStat: (id: string) => dispatch(getEmailContentStat(id)),
  createEmailLeadStat: (formData: FormData, emailContentStatId: string) => dispatch(createEmailLeadStat(formData, emailContentStatId)),
  getEmailLeadStats: (emailContentStatId: string) => dispatch(getEmailLeadStats(emailContentStatId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EmailContentDetails);