const statsEmailsDefaultState = {
    data: [],
    singleItem: {},
    loading: false,
    error: false,
    success: false,
    successMessage: '',
    errorMessage: null
};

const emailContentStatReducer = (state = statsEmailsDefaultState, action) => {
    switch (action.type) {
        case 'START_GET_EMAIL_CONTENT_STAT':
            return {
                ...state,
                loading: true,
                error: false,
                errorMessage: null,
                successMessage: '',
            };
        case 'GET_EMAIL_CONTENT_STAT_SUCCESS':
            return {
                ...state,
                singleItem: action.emailContentStat,
                loading: false,
                error: false,
                errorMessage: null,
            };
        case 'START_GET_EMAIL_CONTENT_STATS':
            return {
                ...state,
                loading: true,
                error: false,
                errorMessage: null,
                successMessage: '',
                success: false
            };
        case 'GET_EMAIL_CONTENT_STATS_SUCCESS':
            return {
                ...state,
                data: action.emailContentStats,
                loading: false,
                error: false,
                errorMessage: null,
            };
        case 'CREATE_EMAIL_CONTENT_STAT_SUCCESS':
            return {
                ...state,
                data: [action.emailContentStat, ...state.data], // Add new item to the data array
                singleItem: action.emailContentStat,
                loading: false,
                success: true,
                error: false,
                errorMessage: null,
                successMessage: 'News Alert has been updated',
            };
        case 'START_EMAIL_CONTENT_STAT_UPDATE':
            return {
                ...state,
                loading: true,
                error: false,
                errorMessage: null,
                successMessage: '',
            };
        case 'UPDATE_EMAIL_CONTENT_STAT_SUCCESS':
            return {
                ...state,
                data: state.data.map((emailContentStat) => {
                    // console.log('emailContentStat: ', emailContentStat);
                    // console.log('action.emailContentStat: ', action.emailContentStat);
                    if (emailContentStat.id === action.emailContentStat.id) {
                        console.log('got match');
                        return {
                            ...emailContentStat,
                            ...action.emailContentStat /// add object to array
                        }
                    } else {
                        return emailContentStat;
                    }
                }),
                loading: false,
                success: true,
                error: false,
                errorMessage: null,
                successMessage: 'Email content stat has been updated',
            };
        case 'START_REMOVE_STATS_EMAIL':
            return {
                ...state,
                loading: true,
                error: false,
                errorMessage: null,
                successMessage: '',
            };
        case 'REMOVE_STATS_EMAIL_SUCCESS':
            return {
                ...state,
                data: state.data.filter(ad => { // check original posts and only include posts that don't have that to delete ID
                    return !action.adIds.includes(ad.id); // includes is checking inside an array of numbers to see if any match
                }),
                loading: false,
                success: true,
                error: false,
                errorMessage: null,
                successMessage: 'Your ads have been removed!',
            };
        case 'STATS_EMAIL_CLEAR_SUCCESS':
            return {
                ...state,
                success: false
            };
        case 'SHOW_ERROR':
            return {
                ...state,
                loading: false,
                error: true,
                successMessage: '',
                errorMessage: action.errorMessage
            };
        default:
            return state;
    }
};

export default emailContentStatReducer;