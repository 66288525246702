import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { getClients } from '../../../../../actions/clients';
import styled from "styled-components";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useHistory, useLocation, useParams } from "react-router-dom";
import ABButton from "../../../../../components/ABButton";
import ABInputField from "../../../../../components/ABInputField";
import colors from '../../../../../styles/colors';
import { Client } from "../../../../../types/types";
import { createEmailContentStat, getEmailContentStat, updateEmailContentStat } from "../../../../../actions/portal/email-content-stat";


const PageWrapper = styled.div`
  max-width: 1100px;
  margin: 0 auto;
`;

const StyledHeading = styled.h1`
  font-size: 32px;
  margin: 20px 0 5px 0;
  color: #333;
`;

const FormSection = styled.div`
  margin-bottom: 20px;
`;

const FormSubHeading = styled.h3`
  font-size: 18px;
  margin-bottom: 10px;
`;

const ActionButtons = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 20px 0;
  div:first-child {
    padding-right: 10px;
  }
`;

const FooterButtons = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
`;

const TextButton = styled.div`
    cursor: pointer;
    margin-right: 5px;
    color: ${colors.blue};
`;

const SubHeading = styled.div`
  padding: 0;
`;

const StyledSelect = styled.select`
  font-size: 16px;
  padding: 9px 10px 10px 10px;
  width: 450px;
  border: 1px solid ${colors.lightGray};
`;

interface EmailContentStat {
    id?: number;
    emailStatId: number;
    clientId: number;
    category?: string;
    imageUrl: string;
    contentType: string;
    externalLink: string;
}

interface EmailContentFormProps {
    createEmailContentStat: (emailContentStat: EmailContentStat) => void;
    getEmailContentStat: (emailContentStatId: string) => void;
    success?: string | null;
    error?: string | null;
    getClients: () => void;
    clients: Client[];
    emailContentStat: EmailContentStat;
    updateEmailContentStat: (queryParams: object, emailContentStatId: string) => void;
}

interface RouteParams {
    emailContentStatId: string;
}

const categories = [
    'bioprocess',
    'upstream',
    'events',
    'downstream',
    'spotlight',
    'fill-finish',
    'solution',
    'career'
];

const contentTypes = [
    'newsletter-ad',
    'featured-content',
    'featured-event',
    'featured-career',
    'sponsored-content',
    'sponsored-podcast',
    'sponsored-event',
    'sponsored-career',
];

const newsletterAdsCategories = [
    'lead-generating-ads',
    'visibility-ads'
];

const EmailContentStatsForm: React.FC<EmailContentFormProps> = ({
    createEmailContentStat,
    success,
    error,
    getClients,
    clients,
    getEmailContentStat,
    emailContentStat,
    updateEmailContentStat
}) => {
    const [category, setCategory] = useState("");
    const [imageUrl, setImageUrl] = useState("");
    const [contentType, setContentType] = useState("");
    const [clientId, setClientId] = useState("");
    const [externalLink, setExternalLink] = useState("");
    const history = useHistory();

    const { emailContentStatId } = useParams<RouteParams>();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);

    const emailStatId = queryParams.get('emailStatId');
    const alertItemId = queryParams.get('alertItemId');
    const alertIssueId = queryParams.get('alertIssueId');

    useEffect(() => {
        if (success) {
            if (!emailContentStatId) {
                toast.success("Email content stat created successfully!");
                history.push(`../email-issue-details/${emailStatId}`);
            } else {
                toast.success("Email content stat updated successfully!");
                history.push(`../../email-issue-details/email-content-details/${emailContentStatId}`);
            }
        }

        if (error) {
            toast.error("Error creating email stat.");
        }
    }, [success, error, history]);

    useEffect(() => {
        getClients();
    }, []);

    // editing
    useEffect(() => {
        if (emailContentStatId) {
            getEmailContentStat(emailContentStatId);
        } else {
            clearForm();
        }
    }, [emailContentStatId]);

    useEffect(() => {
        if (contentType !== 'featured-content' && contentType !== 'sponsored-content' && contentType !== 'newsletter-ad') {
            setCategory('');
        }
    }, [contentType]);

    useEffect(() => {
        if (emailContentStat && emailContentStatId && Object.keys(emailContentStat).length !== 0) {
            setCategory(emailContentStat.category?.toString() || '');
            setImageUrl(emailContentStat.imageUrl?.toString() || '');
            setContentType(emailContentStat.contentType?.toString() || '');
            setClientId(emailContentStat.clientId?.toString() || '');
            setExternalLink(emailContentStat.externalLink?.toString() || '');
        }
    }, [emailContentStat]);

    const clearForm = () => {
        setCategory('');
        setImageUrl('');
        setContentType('');
        setClientId('');
        setExternalLink('');
    };

    const handleInputChange =
        (setter: React.Dispatch<React.SetStateAction<string>>) =>
            (e: React.ChangeEvent<HTMLInputElement>) =>
                setter(e.target.value);

    const handleSelectChange =
        (setter: React.Dispatch<React.SetStateAction<string>>) =>
            (e: React.ChangeEvent<HTMLSelectElement>) =>
                setter(e.target.value);

    const handleSubmit = () => {
        if (!emailStatId || !imageUrl || !contentType || !clientId || !externalLink) {
            toast.error("Please fill out all fields.");
            return;
        }

        const emailContentStat: EmailContentStat = {
            emailStatId: parseInt(emailStatId, 10),
            clientId: parseInt(clientId, 10),
            imageUrl,
            contentType,
            externalLink,
        };

        if (category) {
            emailContentStat.category = category;
        } else {
            emailContentStat.category = '';
        }

        if (emailContentStatId) { // editing
            updateEmailContentStat(emailContentStat, emailContentStatId);
        } else {
            createEmailContentStat(emailContentStat);
        }
    };

    const onCancel = () => {
        if (emailContentStatId) {
            history.push(`../../email-issue-details/email-content-details/${emailContentStatId}`);
        } else {
            history.push(`../email-issue-details/${emailStatId}`);
        }
    }

    return (
        <PageWrapper>
            <StyledHeading>Create stats for a content item</StyledHeading>
            <SubHeading>This will connect a content item to an issue's stats.</SubHeading>

            <FormSection>
                <FormSubHeading>Issue ID:</FormSubHeading>
                {alertIssueId}

                <FormSubHeading>Client ID:</FormSubHeading>
                {
                    (clients && clients.length > 0) &&
                    <StyledSelect
                        name="clientId"
                        value={clientId}
                        onChange={handleSelectChange(setClientId)}
                        placeholder="Enter Client Id"
                    >
                        <option value="">Select</option>
                        {clients.map((client) => (
                            <option key={client.id} value={client.id}>
                                {client.company} - {client.id}
                            </option>
                        ))}
                    </StyledSelect>
                }

                <FormSubHeading>Content Type:</FormSubHeading>
                <StyledSelect
                    name="contentTypes"
                    value={contentType}
                    onChange={handleSelectChange(setContentType)}
                    placeholder="Enter Content Type"
                >
                    <option value="">Select</option>
                    {contentTypes.map((item) => (
                        <option key={item} value={item}>
                            {item}
                        </option>
                    ))}
                </StyledSelect>

                {
                    (contentType === 'featured-content' || contentType === 'sponsored-content') &&
                    <>
                        <FormSubHeading>Category:</FormSubHeading>
                        <StyledSelect
                            name="category"
                            value={category}
                            onChange={handleSelectChange(setCategory)}
                            placeholder="Enter Category"
                        >
                            <option value="">Select</option>
                            {categories.map((item) => (
                                <option key={item} value={item}>
                                    {item}
                                </option>
                            ))}
                        </StyledSelect>
                    </>
                }

                {
                    contentType === 'newsletter-ad' &&
                    <>
                        <FormSubHeading>Category:</FormSubHeading>
                        <StyledSelect
                            name="category"
                            value={category}
                            onChange={handleSelectChange(setCategory)}
                            placeholder="Enter Category"
                        >
                            <option value="">Select</option>
                            {newsletterAdsCategories.map((item) => (
                                <option key={item} value={item}>
                                    {item}
                                </option>
                            ))}
                        </StyledSelect>
                    </>
                }

                <FormSubHeading>Image URL:</FormSubHeading>
                <ABInputField
                    value={imageUrl}
                    onChange={handleInputChange(setImageUrl)}
                    placeholder="Enter Image URL"
                />

                <FormSubHeading>External Link:</FormSubHeading>
                <ABInputField
                    value={externalLink}
                    onChange={handleInputChange(setExternalLink)}
                    placeholder="Enter External Link"
                />
            </FormSection>

            <ActionButtons>
                <FooterButtons>
                    <ABButton
                        title={emailStatId ? "Update Content Stat" : "Create Content Stat"}
                        onClick={handleSubmit}
                    />
                    <TextButton title="cancel" onClick={onCancel}>Cancel</TextButton>
                </FooterButtons>
            </ActionButtons>

            <ToastContainer />
        </PageWrapper>
    );
};

const mapStateToProps = (state: any) => {
    return {
        error: state.emailContentStat.error,
        success: state.emailContentStat.success,
        clients: state.clients.data,
        emailContentStat: state.emailContentStat.singleItem
    };
};

const mapDispatchToProps = (dispatch: any) => ({
    getClients: () => dispatch(getClients()),
    getEmailContentStat: (emailContentStatId: string) => dispatch(getEmailContentStat(emailContentStatId)),
    createEmailContentStat: (queryParams?: object) => dispatch(createEmailContentStat(queryParams)),
    updateEmailContentStat: (queryParams: object, emailContentStatId: string) => dispatch(updateEmailContentStat(queryParams, emailContentStatId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EmailContentStatsForm);

