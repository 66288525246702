import React, { useEffect, HtmlHTMLAttributes, useState } from "react";
import { connect } from "react-redux";
import { history } from "../../../../../routers/AppRouter";
import { getAlertItems } from "../../../../../actions/alert/alert-items";
import styled from 'styled-components';
import MainButton from "../../../../../components/MainButton";
import moment from "moment";
import colors from '../../../../../styles/colors';
import { getEmailStat } from "../../../../../actions/portal/stats-email";
import { EmailContentStatTypes, EmailStatsTypes } from "../../stats-types";
import { Link, useParams } from "react-router-dom";
import { getEmailContentStats } from "../../../../../actions/portal/email-content-stat";
import ABButton from "../../../../../components/ABButton";

const PageWrapper = styled.div`
  max-width: 1100px;
  margin: 0 auto;
  padding: 20px;
`;

const HeadingContainer = styled.div`
  display: flex;
  align-items: center;
`;

const TableStyle = styled.div`
  padding: 40px 0;
`;

const TableHeading = styled.div`
  display: flex;
`;

const TableRow = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  :hover {
    background: ${colors.gray100};
  }
`;

const TableCellHeading = styled.div`
  padding: 10px;
  width: 125px;
  font-weight: bold;
`;

const TableCell = styled.div`
  padding: 10px;
  width: 125px;
  word-wrap: break-word; /* Ensures long words break and wrap */
  overflow-wrap: anywhere; /* Wraps text even for long URLs or words */
`;

const TableCellHeadingLarge = styled.div`
  padding: 10px;
  width: 300px;
  font-weight: bold;
  word-wrap: break-word;
  overflow-wrap: anywhere;
`;

const TableCellLarge = styled.div`
  padding: 10px;
  width: 300px;
`;

const FilterContainer = styled.div`
  display: flex;
  margin: 20px 0 0 0;
`;

interface FilterButtonProps {
  activeFilterTitle?: boolean;
}

const FilterButton = styled.button <FilterButtonProps>`
  background: ${props => props.activeFilterTitle ? colors.blue : colors.white};
  color: ${props => props.activeFilterTitle ? colors.white : colors.black};
  padding: 10px 15px;
  cursor: pointer;
  border: none;
  border-right: 1px solid ${colors.lightGray};
  border-top: 1px solid ${colors.lightGray};
  border-bottom: 1px solid ${colors.lightGray};
  border-left: 1px solid ${colors.lightGray};

  &:nth-child(2), &:last-child {
    border-left: none;
  }
  :hover {
      background-color: ${props => props.activeFilterTitle ? '' : colors.gray100};
  }
`;

const StyledHeading = styled.div`
  padding: 10px 10px 10px 0;
  font-size: 40px;
`;

const SubHeading = styled.div`
  padding: 0;
  font-size: 19px;
  font-weight: bold;
  padding-bottom: 10px;
`;

const Section = styled.div`
  padding: 5px 0;
`;

const Thumbnail = styled.div`
  img {
    width: 75px;
    height: 75px;
  }
`;

interface RouteParams {
  id: string;
}

const EmailIssueDetails = (props: any) => {

  const [activeFilter, setActiveFilter] = useState('draft');
  const { id } = useParams<RouteParams>();

  useEffect(() => {
    console.log('id: ', id);

    if (id) {
      props.getEmailStat(id);
      props.getEmailContentStats(id);
    }
  }, [id]);

  const handleButtonClick = (e: React.MouseEvent<HTMLButtonElement>, title: string) => {
    setActiveFilter(title);
    // if (title === 'all') {
    //     props.getStatsEmail();
    // } else {
    //     props.getStatsEmail({ status: title });
    // }
  }

  const createDetailForm = () => {
    history.push({
      pathname: `/portal/email-performance/email-content-stats-form/`,
      search: `?emailStatId=${id}&alertItemId=${props.emailStat.alertItem.id}&alertIssueId=${props.emailStat.alertItem.issue}`,
    });
  }

  const handeViewAlert = (id: number) => {
    history.push(`/alert/${id}`);
  }

  const handeDetailNavigate = (emailContentId: number) => {
    history.push({
      pathname: `/portal/email-performance/email-issue-details/email-content-details/${emailContentId}`,
    });
  }

  const handleEdit = () => {
    console.log(props.emailStat.id);
    history.push({
      pathname: `/portal/email-performance/email-stats-form/${props.emailStat.id}`,
      state: {
        data: props.emailStat.id,
      },
    });
  };

  return (
    <>
      <PageWrapper>
        <div><Link to="../../email-performance">Performance</Link> - Issue Details</div>
        <HeadingContainer>
          <StyledHeading>Email Issue Details</StyledHeading>
          {
            id &&
            <MainButton onClick={createDetailForm} title="Connect Content" />
          }
        </HeadingContainer>
        {
          (props.emailStat && props.emailStat.alertItem) &&
          <div>
            <Section><strong>Issue:</strong> {props.emailStat.alertItem?.issue}</Section>
            <Section><strong>Newsletter Link:</strong> {props.emailStat.newsletterLink}</Section>
            <Section>
              <div><strong>Date:</strong></div>
              <div>{moment(props.emailStat.alertItem?.date).format('MMM. Do, YYYY')}</div>
            </Section>
            <Section>
              <div><strong>Subject:</strong></div>
              <div>{props.emailStat.alertItem?.title}</div>
            </Section>
            <Section><strong>Sent:</strong> {props.emailStat.sent.toLocaleString()}</Section>
            <Section><strong>Delivered:</strong> {props.emailStat.delivered.toLocaleString()}</Section>
            <Section><strong>Bounce Rate:</strong> {props.emailStat.bounceRate}%</Section>
            <Section><strong>Open Rate:</strong> {props.emailStat.openRate}%</Section>
            <Section><strong>Opens:</strong> {props.emailStat.opens.toLocaleString()}</Section>
            <Section><strong>Clicks:</strong> {props.emailStat.clicks.toLocaleString()}</Section>
            <Section><strong>CTR:</strong> {props.emailStat.ctr}%</Section>
            <Section><strong>CTOR:</strong> {props.emailStat.ctor}%</Section>
            <Section><button onClick={handleEdit}>Edit Overall Stats</button></Section>
          </div>
        }

        <TableStyle>
          <SubHeading>Content in this Alert issue</SubHeading>

          <TableHeading>
            <TableCellHeading>Image Url</TableCellHeading>
            <TableCellHeading>Id</TableCellHeading>
            <TableCellHeading>Client Id</TableCellHeading>
            <TableCellHeading>Content Type</TableCellHeading>
            <TableCellHeading>Category</TableCellHeading>
            <TableCellHeading>External Link</TableCellHeading>
            <TableCellHeading>Leads</TableCellHeading>
          </TableHeading>
          {
            (props.emailContentStats && props.emailContentStats.length > 0) &&
            props.emailContentStats.map((item: EmailContentStatTypes) => {
              return (
                <TableRow key={item.id} onClick={() => handeDetailNavigate(item.id!)}>
                  <TableCell><Thumbnail><img src={item.imageUrl} /></Thumbnail></TableCell>
                  <TableCell>{item.id}</TableCell>
                  <TableCell>{item.clientId}</TableCell>
                  <TableCell>{item.contentType}</TableCell>
                  <TableCell>{item.category}</TableCell>
                  <TableCell>
                    {item.externalLink ? (
                      <a href={item.externalLink} target="_blank" rel="noopener noreferrer">
                        Link
                      </a>
                    ) : (
                      'NA'
                    )}
                  </TableCell>
                  <TableCell>{(item.emailLeadStats && item.emailLeadStats.length) ? item.emailLeadStats.length : 'N/A'}</TableCell>
                </TableRow>
              );
            })
          }
        </TableStyle>

      </PageWrapper>
    </>
  )
}

const mapStateToProps = (state: any) => {
  return {
    emailStat: state.statsEmail.emailStat,
    emailContentStats: state.emailContentStat.data,
  };
};

const mapDispatchToProps = (dispatch: any) => ({
  getEmailStat: (id: string) => dispatch(getEmailStat(id)),
  getEmailContentStats: (id: string) => dispatch(getEmailContentStats(id))
});

export default connect(mapStateToProps, mapDispatchToProps)(EmailIssueDetails);