import React, { useEffect, HtmlHTMLAttributes, useState } from "react";
import { connect } from "react-redux";
import { history } from "../../../../routers/AppRouter";
import { getAlertItems } from "../../../../actions/alert/alert-items";
import styled from 'styled-components';
import MainButton from "../../../../components/MainButton";
import moment from "moment";
import colors from '../../../../styles/colors';
import { getEmailStats } from "../../../../actions/portal/stats-email";
import { EmailStatsTypes } from "../stats-types";
import ABSmallButton from "../../../../components/ABSmallButton";

const PageWrapper = styled.div`
  max-width: 1100px;
  margin: 0 auto;
  padding: 20px;
`;

const HeadingContainer = styled.div`
  display: flex;
  align-items: center;
`;

const TableStyle = styled.div`
  padding: 40px 0;
`;

const TableHeading = styled.div`
  display: flex;
`;

const TableRow = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  :hover {
    background: ${colors.gray100};
  }
`;

const TableCellHeading = styled.div`
  padding: 10px;
  width: 125px;
  font-weight: bold;
`;

const TableCell = styled.div`
  padding: 10px;
  width: 125px;
`;

const TableCellHeadingLarge = styled.div`
  padding: 10px;
  width: 300px;
  font-weight: bold;
`;

const TableCellLarge = styled.div`
  padding: 10px;
  width: 300px;
`;

const FilterContainer = styled.div`
  display: flex;
  margin: 20px 0 0 0;
`;

interface FilterButtonProps {
  activeFilterTitle?: boolean;
}

const FilterButton = styled.button <FilterButtonProps>`
  background: ${props => props.activeFilterTitle ? colors.blue : colors.white};
  color: ${props => props.activeFilterTitle ? colors.white : colors.black};
  padding: 10px 15px;
  cursor: pointer;
  border: none;
  border-right: 1px solid ${colors.lightGray};
  border-top: 1px solid ${colors.lightGray};
  border-bottom: 1px solid ${colors.lightGray};
  border-left: 1px solid ${colors.lightGray};

  &:nth-child(2), &:last-child {
    border-left: none;
  }
  :hover {
      background-color: ${props => props.activeFilterTitle ? '' : colors.gray100};
  }
`;

const StyledHeading = styled.div`
  padding: 10px 10px 10px 0;
  font-size: 40px;
`;

const EmailStats = (props: any) => {

  const [activeFilter, setActiveFilter] = useState('draft');

  useEffect(() => {
    props.getEmailStats();
  }, []);

  const handleButtonClick = (e: React.MouseEvent<HTMLButtonElement>, title: string) => {
    setActiveFilter(title);
    if (title === 'all') {
      props.getEmailStats();
    } else {
      props.getEmailStats({ status: title });
    }
  }

  const statImportNavigate = () => {
    history.push('/portal/email-performance/email-stats-form');
  }

  const handeDetailNavigate = (id: number) => {
    history.push({
      pathname: `/portal/email-performance/email-issue-details/${id}`,
    });
  }

  return (
    <>
      <PageWrapper>
        <HeadingContainer>
          <StyledHeading>Email Performance</StyledHeading>
          <MainButton onClick={statImportNavigate} title="Create Email Stats" />
        </HeadingContainer>
        {/* <FilterContainer>
                    <FilterButton activeFilterTitle={activeFilter === 'draft'} onClick={(e) => handleButtonClick(e, 'draft')}>Draft</FilterButton>
                    <FilterButton activeFilterTitle={activeFilter === 'published'} onClick={(e) => handleButtonClick(e, 'published')}>Published</FilterButton>
                    <FilterButton activeFilterTitle={activeFilter === 'all'} onClick={(e) => handleButtonClick(e, 'all')}>All</FilterButton>
                </FilterContainer> */}
        <TableStyle>
          <TableHeading>
            <TableCellHeading>Alert Id</TableCellHeading>
            <TableCellHeading>Issue Id</TableCellHeading>
            <TableCellHeading>Id</TableCellHeading>
            {/* <TableCellHeading>Issue #</TableCellHeading> */}
            {/* <TableCellHeadingLarge>Subject</TableCellHeadingLarge> */}
            <TableCellHeading>Date</TableCellHeading>
            {/* <TableCellHeading>Link</TableCellHeading> */}
            <TableCellHeading>Sent</TableCellHeading>
            <TableCellHeading>Delivered</TableCellHeading>
            {/* <TableCellHeading>Open Rate (%)</TableCellHeading> */}
            <TableCellHeading>Opens</TableCellHeading>
            <TableCellHeading>Clicks</TableCellHeading>
            {/* <TableCellHeading>CTR (%)</TableCellHeading>
                        <TableCellHeading>CTOR (%)</TableCellHeading> */}
            {/* <TableCellHeading>Delivery Rate (%)</TableCellHeading> */}
            {/* <TableCellHeading>Bounce Rate (%)</TableCellHeading>
                        <TableCellHeading>Unsubscribe Rate (%)</TableCellHeading>
                        <TableCellHeading>Spam Rate (%)</TableCellHeading>
                        <TableCellHeading>Growth Rate (%)</TableCellHeading> */}
            {/* <TableCellHeading>Leads</TableCellHeading> */}
          </TableHeading>
          {
            (props.statsEmail && props.statsEmail.length > 0) &&
            props.statsEmail.map((item: EmailStatsTypes) => {
              return (
                <TableRow key={item.id} onClick={() => handeDetailNavigate(item.id!)}>
                  <TableCell>{item.alertItemId}</TableCell>
                  <TableCell>{item.alertItem!.issue}</TableCell>
                  <TableCell>{item.id}</TableCell>
                  {/* <TableCell>{item.issue}</TableCell> */}
                  {/* <TableCellLarge>{item.subject}</TableCellLarge> */}
                  <TableCell>{moment(item.alertItem!.date).format('MMM. Do, YYYY')}</TableCell>
                  {/* <TableCell><a href={item.link}>Link</a></TableCell> */}
                  <TableCell>{item.sent}</TableCell>
                  <TableCell>{item.delivered}</TableCell>
                  {/* <TableCell>{item.openRate}%</TableCell> */}
                  <TableCell>{item.opens}</TableCell>
                  <TableCell>{item.clicks}</TableCell>
                  {/* <TableCell>{item.ctr}%</TableCell>
                                    <TableCell>{item.ctor}%</TableCell> */}
                  {/* <TableCell>{item.deliveryRate}%</TableCell> */}
                  {/* <TableCell>{item.bounceRate}%</TableCell>
                                    <TableCell>{item.unsubscribeRate}%</TableCell>
                                    <TableCell>{item.spamRate}%</TableCell>
                                    <TableCell>{item.growthRate !== undefined ? `${item.growthRate}%` : 'N/A'}</TableCell> */}
                  {/* <TableCell>{item.leads}</TableCell> */}
                </TableRow>
              );
            })
          }
        </TableStyle>
      </PageWrapper >
    </>
  )
}

const mapStateToProps = (state: any) => {
  return {
    statsEmail: state.statsEmail.data
  };
};

const mapDispatchToProps = (dispatch: any) => ({
  getEmailStats: (queryParams?: object) => dispatch(getEmailStats(queryParams))
});

export default connect(mapStateToProps, mapDispatchToProps)(EmailStats);